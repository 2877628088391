/* wedo styling */
/* wedo styling */
.par-wedo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.sub-wedo{
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6rem 0 2rem 0;
  
}
.sub-wedo h3{
    /* background-color: var(--white-color); */
    /* padding: 10px 20px; */
    /* border-radius: 40px 80px; */
    color: #3662FD;
    /* font-size: 16px; */
    
}
@media screen and (min-width:992px) {
    .odd:nth-child(odd){
    flex-direction: row-reverse !important;
    text-align: end;
}
}
/* wedo styling */
/* wedo styling */

/* service styling */
/* service styling */
.par-service{
   width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.sub-service{
   width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 70vh;
  margin: 2rem 0 1rem 0;
  position: relative;
}
.sub-service img{
  position: absolute;
  width: 8% !important;
}
.ser1{
  right: 0;
}
.ser2{
  top: 0;
}
.ser3{
  bottom: 0;
}
.ser4{
  left: 0;
}
@media screen and (max-width:768px) {
  .ser1{
  bottom: -10%;
  left: 50%;
}
.ser3{
  bottom: 0;
  right: 0;
}
.ser4{
  left: 0;
  bottom: 0;
}
}
.backloor{
  position: absolute;
  width: 100%;
  opacity: 20%;
  /* position: relative; */
}
.sub-service h1{
  font-size: 3.8rem;
  max-width: 65%;
  margin-top: 20px;
}
.backloor img{
  z-index: 8;
}
@media screen and (max-width:1050px) {
  .sub-service h1{
  max-width: 82%;
}
}
@media screen and (max-width:876px) {
    .sub-service h1{
  max-width: 85%;
}
}
@media screen and (max-width:708px) {
    .sub-service h1{
  max-width: 90%;
}
}
@media screen and (max-width:630px) {
    .sub-service h1{
  font-size: 3rem;
}
}
@media screen and (max-width:480px) {
    .sub-service h1{
  font-size: 2.5rem;
}
}
@media screen and (max-width:400px) {
    .sub-service h1{
  font-size: 2.2rem;
}
}
@media screen and (max-width:350px) {
    .sub-service h1{
  font-size: 2rem;
}
}
@media screen and (max-width:315px) {
    .sub-service h1{
  font-size: 1.8rem;
}
}
@media screen and (max-width:289px) {
    .sub-service h1{
  font-size: 1.7rem;
}
}
/* service styling */
/* service styling */























