.sub-coursecurriculum .faqaccor .points {
  cursor: pointer;
  background-color: #090808;
  border-radius: 8px;
  padding: 18px 35px;
  transition: 0.5s linear;
}
.sub-coursecurriculum .faqaccor .pointss {
  cursor: pointer;
  background-color: rgba(0, 180, 250, 0.3);
  padding: 18px 35px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  transition: 0.5s linear;
}
.sub-coursecurriculum .faqaccor {
  width: 100% !important;
}
.rotc {
  rotate: 0deg;
  transition: 0.5s linear;
  font-weight: 900;
  color: #3662FD;
  text-align: start;
  max-width: 40px;
}
.notc {
  rotate: -180deg;
  transition: 0.5s linear;
  font-weight: 900;
  color: #3662FD;
  text-align: start;
}
.par-coursecurriculum {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color);
  color: var(--text-color);
}
.sub-coursecurriculum {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 4rem 0;
}
.sub-coursecurriculum .allcurri {
  margin-bottom: 6px !important;
  /* padding: 0 1rem; */
}
.sub-coursecurriculum .contcurri {
  background-color: rgba(0, 180, 250, 0.3);
  padding: 20px 45px 50px 55px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen and (max-width: 992px) {
  .sub-coursecurriculum {
    width: 90%;
  }
}
@media screen and (max-width: 568px) {
  .sub-coursecurriculum .contcurri {
    padding: 20px 20px 50px 10px;
  }
  .sub-coursecurriculum .faqaccor .points, .sub-coursecurriculum .faqaccor .pointss {
    padding: 18px 12px;
  }
}

.sub-coursecurriculum i{
  height: 2rem;
  width: 2rem;
  /* padding: 10px; */
  border-radius: 50%;
  color: var(--white-color);
  background-color: #001E88;
  font-size: 1.2rem;
  /* line-height: 32px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}