.par-partners{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-partners{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0 0px 0;
}
.sub-partners img{
    width: 100%;
    height: 80px;
    /* filter: grayscale(100%);
    background-color: black;
    color: white; */
    object-fit: contain;
    object-position: center;
}
@media screen and (max-width:992px) {
    .sub-partners img{
        width: 100%;
    }
}

 /* the slides */
.slick-slider {
    overflow: hidden;
}
/* the parent */
.slick-list {
    margin: 0 -9px !important;
}
/* item */  
.item{
    padding: 0 9px;
}
.slick-slider .slick-slide {
  padding: 0 20px;
}