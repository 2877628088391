.par-testimonials {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
font-family: 'Plus Jakarta Sans', sans-serif;
}
.sub-testimonials {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7rem 0 4rem 0;
}
.sub-testimonials .grad {
  width: 60%;
  height: 55vh;
  /* top: 10%; */
  background: linear-gradient(
    90deg,
    #44ff9a -0.55%,
    #44b0ff 22.86%,
    #8b44ff 48.36%,
    #ff6644 73.33%,
    #ebff70 99.34%
  );
  opacity: 0.05;
  position: absolute;
}
.sub-testimonials h1{
    margin-bottom: 30px !important;
}
@media only screen and (max-width: 992px) {
  .sub-testimonials .grad {
    width: 90%;
  }
}
.sub-testimonials i {
  color: #f59e0b;
  padding-bottom: 15px;
}

.test-dark{
  background-color: #ffffff !important;
}
.test-dark{
  background-color: #000000 !important;
}
/* .slick-slide{
height: inherit !important;
}
.slide{
    height: 100% !important;
} */
