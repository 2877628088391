.bount {
  width: 90% !important;
  padding: 1.5rem 0 0rem 0 !important;
}

.footer-light{
    background-color: var(--white-color);
    color: var(--sec-black-color);
}
.footer-dark{
    background-color: var(--sec-black-color);
      color: var(--white-color);
}

footer a{
  text-decoration: none;
  color: var(--white-color);
}
footer a:hover{
  text-decoration: none;
  color: var(--white-color);
}

/* footer hr {
  height: 4px;
  background-color: var(--white-color) !important;
} */

.dark-icon svg{
  color: var(--black-color) !important;
}
.light-icon svg{
  color: var(--white-color) !important;
}

.mints{
    width: 100% ;
}
@media only screen and (min-width: 992px) {
  .mints {
    width: 60% !important;
  }
}

/* @media screen and (max-width: 768px) {
  .mints a svg{
    width: 40px !important;
  }
} */
/* .icans{
    border: 1px solid var(--light-color);
    border-radius: 50%;
} */
