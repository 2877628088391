/* portfolio styling */
/* portfolio styling */
.par-portfolio {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  font-family: 'Inter', sans-serif;
}
.sub-portfolio {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0 3rem 0;
}

.darklink-port {
  text-decoration: none;
  color: var(--black-color);
}

.lightlink-port {
  text-decoration: none;
  color: var(--white-color);
}

.sub-portfolio a:is(.darklink-port):hover {
  text-decoration: none;
  color: var(--black-color);
}

.sub-portfolio a:is(.lightlink-port):hover {
  text-decoration: none;
  color: var(--white-color);
}

.portfolio-img {
  object-fit: cover;
  object-position: top left;
  height: 300px;
}

.eachport:nth-child(even) {
  margin-top: 3rem;
}

@media screen and (max-width: 992px) {
  .eachport {
    margin-top: 0 !important;
    margin-bottom: 3.5rem;
  }
}
.sub-portfolio h3 {
  color: #3662fd;
}
/* portfolio styling */
/* portfolio styling */

/* product styling */
/* product styling */
.par-product {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
}
.sub-product {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0rem 0;
}

/* Apply the animation to the image */
.sub-product img {
  animation: rotate 10s infinite;
}

/* Define the animation */
/* @keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* product styling */
/* product styling */

@media screen and (min-width: 992px) {
  .par-portfolio .eachport:nth-child(even) {
  padding-left: 40px;
}
.par-portfolio .eachport:nth-child(odd) {
  padding-right: 40px;
}
}
