:root {
  --white-color: #ffffff;
  --black-color: #000000;
  --sec-black-color: #050505;
  --blue-color: #3662FD;
  --star-color: #f59e0b;

  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: var(--black-color);
}
::-webkit-scrollbar-thumb {
  background: var(--white-color);
  height: 1rem;
}

@media screen and (max-width: 768px) {
  .allsub {
    width: 95% !important;
  }
}
@media screen and (max-width: 992px) {
  .otherway {
    flex-direction: column-reverse !important;
  }
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* react slick */
.slick-initialized {
  overflow: hidden;
}


/* light and dark mode styling */
.light{
  background-color: var(--white-color);
  color: var(--black-color);
}

.dark{
  background-color: var(--black-color);
  color: var(--white-color);
}

.css-j204z7-MuiFormControlLabel-root{
  margin-right: -8px !important;
}