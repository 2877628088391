@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

body {
  /* font-family: 'Inter', sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5 !important;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* img{
  object-fit: cover;
  object-position: center;
} */