.not-found {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.not-found h1 {
  font-size: 12rem;
}

.btc {
  align-items: center !important;
  border-radius: 3px !important;
  padding: 10px 30px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.dark-btc{
  background-color: var(--black-color) !important;
  color: var(--white-color) !important;
}

.light-btc{
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}

.btc:hover {
  text-decoration: none !important;
  color: var(--blue-color) !important;
  background: inherit !important;
}

@media screen and (max-width: 400px) {
  .not-found h1 {
    font-size: 8rem;
  }
}
