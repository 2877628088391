.modals {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 10000;
}

.modal-background {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden !important;
  justify-content: center;
  align-items: center;
}

.modal-card {
  position: relative;
  width: 500px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.modal-close:before,
.modal-close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #333;
  transform: translate(-50%, -50%);
}

.modal-close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal-close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.show-white{
  background-color: #ffffff;
}

.phonein input{
  border: 0 !important;
  outline: 0 !important;
}

/* The Close Button */
.close {
  color: #000000;
  float: right;
  font-size: 28px;
  margin-bottom: 40px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}