/* home styling */
/* home styling */
.par-home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
}
.sub-home {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 70vh;
    margin: 2rem 0 1rem 0;
  position: relative;
}
.sub-home img {
  position: absolute;
  width: 20% !important;
}
.home3 {
  right: 0;
}
.home {
  top: 0;
}
.home2 {
  bottom: -15%;
}
.home1 {
  left: 0;
}
@media screen and (max-width: 768px) {
  .home2 {
    bottom: -10%;
    left: 40%;
  }
  .home3 {
    bottom: -10%;
    right: 0;
  }
  .home1 {
    left: 0;
    bottom: -10%;
  }
}

.sub-home h1 {
  font-size: 4rem;
  max-width: 60%;
  margin-top: 20px;
  font-weight: 800;
}
.sub-home p {
  font-weight: 400;
}
@media screen and (max-width: 1005px) {
  .sub-home h1 {
    max-width: 70%;
  }
}
@media screen and (max-width: 876px) {
  .sub-home h1 {
    max-width: 85%;
  }
}
@media screen and (max-width: 708px) {
  .sub-home h1 {
    max-width: 95%;
  }
}
@media screen and (max-width: 630px) {
  .sub-home h1 {
    font-size: 3.4rem;
  }
}
@media screen and (max-width: 540px) {
  .sub-home h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 480px) {
  .sub-home h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .sub-home h1 {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 350px) {
  .sub-home h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 318px) {
  .sub-home h1 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 289px) {
  .sub-home h1 {
    font-size: 1.7rem;
  }
}
/* home styling */
/* home styling */

/* numbers styling */
/* numbers styling */
.par-numbers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
}
.sub-numbers {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0 3rem 0;
}

.sub-numbers h1 {
  font-weight: 700;
}

.sub-numbers p {
  font-weight: 400;
}

/* numbers styling */
/* numbers styling */

/* teamwork styling */
/* teamwork styling */
.par-teamwork {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
}
.sub-teamwork {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7rem 0 4.5rem 0;
}

.sub-teamwork h1 {
  font-weight: 700;
}

.sub-teamwork p {
  font-weight: 400;
}

/* teamwork styling */
/* teamwork styling */

/* courses styling */
/* courses styling */
.par-courses {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.par-courses img {
  position: absolute;
  z-index: 2;
  opacity: 20%;
  width: 100%;
  height: 100%;
}
.sub-courses {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7rem 0 3rem 0;
}
.sub-courses .row h5 {
  color: var(--blue-color);
  font-weight: 700;
}

.sub-courses .row p {
  font-weight: 400;
}
.card {
  --bs-card-border-color: none !important;
}
.dark .borderlinelr {
  border-right: 1px solid var(--white-color);
  border-left: 1px solid var(--white-color);
}

.dark .borderlineb {
  border-bottom: 1px solid var(--white-color);
}

.light .borderlinelr {
  border-right: 1px solid var(--black-color);
  border-left: 1px solid var(--black-color);
}

.light .borderlineb {
  border-bottom: 1px solid var(--black-color);
}

@media screen and (max-width: 992px) {
  .light .borderlinelr,
  .light .borderlineb {
    border: none;
  }

  .dark .borderlinelr,
  .dark .borderlineb {
    border: none;
  }
  .light .borall {
    border-bottom: 1px solid var(--black-color) !important;
  }

  .dark .borall {
    border-bottom: 1px solid var(--white-color) !important;
  }
}
@media screen and (max-width: 768px) {
  .light .boralls {
    border-bottom: 1px solid var(--black-color) !important;
  }

  .dark .boralls {
    border-bottom: 1px solid var(--white-color) !important;
  }
}
/* courses styling */
/* courses styling */
