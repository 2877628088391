/* about styling */
/* about styling */
.par-about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.sub-about {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7rem 0;
}
/* about styling */
/* about styling */

/* mission styling */
/* mission styling */
.par-mission {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;
}
.sub-mission {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 5rem 0;
}
/* mission styling */
/* mission styling */

/* vision styling */
/* vision styling */
.par-vision {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.sub-vision {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 5rem 0;
}

/* vision styling */
/* vision styling */

/* contactus styling */
/* contactus styling */
.par-contactus {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.pas-contact-us{
  background-color: var(--sec-black-color);
  color: var(--white-color);
}
.sub-contactus {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0rem 0 6rem 0;
  
}
/* contactus styling */
/* contactus styling */

/* team styling */
/* team styling */
.par-team {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Inter', sans-serif;

}
.sub-team {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 5rem 0;
}
.temg:nth-child(2) img,
.temg:nth-child(5) img{
  width: 210px !important;
  height: 210px !important;
}
.temg:nth-child(2),
.temg:nth-child(5){
  margin-bottom: 35px !important;
}

.teams{
  width: 180px;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

@media only screen and (max-width:992px) {
  .array {
    display: flex;
    flex-direction: row-reverse;
  }

  .temg:nth-child(2) {
    order: -1;
  }
  .temg:nth-child(5) {
    order: -4;
  }
  /* .temg img{
    width: 80% !important;
  }
  .temg:nth-child(2) img,
.temg:nth-child(5) img{
  width: 80% !important;
} */
.teams, .temg:nth-child(2) img,
.temg:nth-child(5) img{
  width: 210px !important;
  height: 210px !important;
}
.temg{
    margin-bottom: 35px !important;
}

.sub-mission img, .sub-vision img{
  width: 60%;
}
}
@media screen and (max-width:768px) {
  .sub-mission img, .sub-vision img{
  width: 80%;
}
}
/* team styling */
/* team styling */
